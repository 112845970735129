<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        md="10"
        xl="6"
      >
        <v-card>
          <v-container class="pa-6">
            <select-image
              ref="selectImage"
              :initial-image="offer && offer.photo"
              :disabled="true"
              :width="240"
              :height="135"
              label="Picture"
            />
            <v-text-field
              :value="offer.title"
              :disabled="true"
              label="Title"
              clearable
              placeholder="No title"
              class="mt-6"
            />
            <div class="about-container">
              <label>About</label>
              <p
                class="ql-editor"
                v-html="$sanitize(offer.about)"
              />
            </div>
            <v-text-field
              :value="offer.categories"
              :disabled="true"
              label="Categories"
              clearable
              placeholder="No category"
              class="mt-2"
            />
            <v-text-field
              :value="offer.phases.join(', ')"
              :disabled="true"
              label="Phases"
              clearable
              placeholder="No phase"
              class="mt-2"
            />
            <v-text-field
              :value="offer.price"
              :disabled="true"
              label="Price"
              clearable
              type="number"
              placeholder="No price"
              class="mt-2"
            />
            <v-text-field
              :value="offer.languages.join(', ')"
              :disabled="true"
              label="Languages"
              clearable
              placeholder="No language"
              class="mt-2"
            />
            <v-text-field
              :value="offer.address"
              :disabled="true"
              label="Address"
              clearable
              placeholder="No address"
              class="mt-2"
            />
            <label>Video</label>
            <label
              v-if="!offer.video"
              class="d-block"
            >
              No video uploaded
            </label>
            <div
              class="video_container mt-2 mb-8"
            >
              <video-display
                :edit-mode="true"
                :url="offer.video"
                :storage-key="storageKey"
              >
                <template #video-format-unsupported-error-message>
                  Video format unsupported.
                </template>
              </video-display>
            </div>
            <v-checkbox
              v-model="offer.isPublished"
              :disabled="true"
              class="mt-2"
              color="primary"
              label="Published on Services"
            />
            <app-button
              block
              class="mb-4"
              @click="backToOffers"
            >
              Back to Offers
            </app-button>
            <label>* Mandatory field</label>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { SelectImage } from '@kickbox/common-admin';
import { VideoDisplay } from '@kickbox/common-components';

export default {
  components: {
    VideoDisplay,
    SelectImage
  },
  props: ['id'],
  data() {
    return {
      fromExternal: false,
    };
  },
  computed: {
    ...mapGetters([
      'offerById',
      'company'
    ]),
    offer() {
      return this.offerById(this.id);
    },
    storageKey() {
      return `videos/offer-${this.id}`;
    },
    photo() {
      return this.$refs.selectImage.image;
    }
  },
  async created() {
    if (!this.offer) {
      this.$router.push({ name: 'AdminHome' });
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromExternal = from.fullPath === '/';
    });
  },
  methods: {
    backToOffers() {
      this.$router.push({ name: 'AdminManageOffers' });
    }
  }
};
</script>

<style scoped lang="scss">
@import '~quill/dist/quill.core.css';
label {
  color: rgba(0, 0, 0, .54);
  font-size: 12px;
}

.add-link {
  font-size: 12px;
  cursor: pointer;
}

.croppa-container {
  border: 1px solid grey;
}

.video_container {
  max-width: 500px;
}

.about-container {
  color: #808080;
  p {
    padding-left: 0;
  }
  .ql-editor {
    ol, ul {
      padding-left: 0.2rem;
    }

    li.ql-indent-1:not(.ql-direction-rtl) {
      padding-left: 3em;
    }

    li {
      margin: 0.1rem 0;
    }
  }
}
</style>
